// import Seo from "gatsby-plugin-wpgraphql-seo"
import "../css/gutenberg.css"
import "moment/locale/de"
import "twin.macro"
import "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import BackButton from "../components/back-button"
import Container from "../components/container"
import Copyright from "../components/copyright"
import EventList from "../components/events/list"
import parse from "html-react-parser"
import React from "react"

const Protagonist = ({
  data: {
    protagonist: {
      member: { title, firstname, lastname, jobtitle, website, linkedin, twitter, instagram },
      id,
      content,
      featuredImage,
      seo,
    },
    events,
    site: {
      siteMetadata: { siteUrl },
    },
  },
}) => {

  events = events.edges.map(({ node }) => node)
  events = events.filter(
    event =>
      event?.protagonists?.moderation?.some(prot => prot.id === id) ||
      event?.protagonists?.together?.some(prot => prot.id === id)
  )

  const hasCopyright =
    featuredImage?.node?.imageCopyright.photographer ||
    featuredImage?.node?.imageCopyright.rights

  return (
    <div tw="mb-20">
      {/* <Seo post={pageReplaced} /> */}
      <Container>
        <div tw="mt-6 mb-11">
          <BackButton />
        </div>
        <div tw="mb-10 lg:mb-10">
          {firstname && (
            <h1 tw="max-w-3xl text-3xl font-bold lg:text-5xl">{title} {firstname} {lastname}</h1>
          )}
        </div>
        <div tw="grid grid-cols-12 gap-y-10 lg:gap-5">
          <div tw="relative col-span-12 lg:col-span-3 xl:col-span-2">
            {website && (
              <a
                href={website}
                target="_blank"
                rel="noopener noreferrer"
                tw="block text-xl font-bold"
              >
                Website
              </a>
            )}
            {twitter && (
              <a
                href={twitter}
                target="_blank"
                rel="noopener noreferrer"
                tw="block text-xl font-bold"
              >
                Twitter
              </a>
            )}
            {instagram && (
              <a
                href={instagram}
                target="_blank"
                rel="noopener noreferrer"
                tw="block text-xl font-bold"
              >
                Instagram
              </a>
            )}
            {linkedin && (
              <a
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
                tw="block text-xl font-bold"
              >
                LinkedIn
              </a>
            )}
          </div>
          <div tw="col-span-12 col-start-1 lg:col-span-9 lg:col-start-4 xl:col-start-3">
            <div className="wp-content" tw="mb-11">
              {content && <>{parse(content)}</>}
            </div>
            <figure tw="w-1/2">
              <div tw="relative mb-4 bg-sand-500 aspect-ratio aspect-ratio-1/1">
                {!!hasCopyright && (
                  <Copyright
                    photographer={
                      featuredImage.node?.imageCopyright.photographer
                    }
                    rights={featuredImage.node?.imageCopyright.rights}
                  />
                )}
                {featuredImage && (
                  <GatsbyImage
                    image={
                      featuredImage?.node?.localFile?.childImageSharp
                        ?.gatsbyImageData
                    }
                    alt={title}
                    tw="object-contain aspect-ratio-item"
                    load="lazy"
                  />
                )}
              </div>
            </figure>
          </div>
        </div>
        {events.length > 0 && (
          <div tw="mt-24">
            <h2 tw="mb-9 max-w-3xl text-3xl font-bold lg:text-5xl">
              Programmpunkte
            </h2>
            <EventList events={events} />
          </div>
        )}
      </Container>
    </div>
  )
}

export default Protagonist

//export { Head } from "../components/head"

export const pageQuery = graphql`
  query Protagonist($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wp {
      seo {
        schema {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    seo: wpProtagonist(id: { eq: $id }) {
      ...SeoFragmentProtagonist
    }

    protagonist: wpProtagonist(id: { eq: $id }) {
      nodeType
      id
      #title
      content
      uri
      member {
        title
        firstname
        lastname
        jobtitle
        linkedin
        twitter
        website
        instagram
      }
      featuredImage {
        node {
          altText
          imageCopyright {
            photographer
            rights
          }
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 60
                placeholder: BLURRED
                layout: FULL_WIDTH
                breakpoints: [320, 480, 640, 756, 920]
              )
            }
          }
        }
      }
      # seo {
      #   title
      #   metaDesc
      #   focuskw
      #   metaKeywords
      #   metaRobotsNoindex
      #   metaRobotsNofollow
      #   opengraphTitle
      #   opengraphDescription
      #   opengraphImage {
      #     altText
      #     sourceUrl
      #     srcSet
      #   }
      #   twitterTitle
      #   twitterDescription
      #   twitterImage {
      #     altText
      #     sourceUrl
      #     srcSet
      #   }
      #   canonical
      #   cornerstone
      #   schema {
      #     articleType
      #     pageType
      #     raw
      #   }
      # }
    }

    events: allWpEvent(sort: { fields: startDate, order: ASC }) {
      edges {
        node {
          id
          title
          slug
          uri
          content
          excerpt
          allDay
          date
          duration
          endDate

          protagonists {
            moderation {
              ... on WpProtagonist {
                id
                #title
                member {
                  title
                  firstname
                  lastname
                }
              }
            }
            together {
              ... on WpProtagonist {
                id
                #title
                member {
                  title
                  firstname
                  lastname
                }
              }
            }
          }
          eventsCategories {
            nodes {
              name
            }
          }
          featured
          organizers {
            nodes {
              title
            }
          }
          venue {
            title
          }
          startDate
          status
        }
      }
    }
  }
`
